//* External
import { Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

//* App Custom
import { Button, ButtonsList, Wizard } from '@componentsApp/common/index';

const WizardModal = ({
  currentView,
  steps,
  handleSubmit,
  body = { step_0: [] },
  submits = { step_0: () => {} },
  submitBtnText = { step_0: 'buttons.manage' },
  submitBtnTitle = { step_0: '' },
  submitBtnDisabled = { step_0: false },
  extraFooterBtns = { step_0: [] },
  onHide = () => {},
  size = 'md'
}) => {
  const extraButtons = extraFooterBtns[`step_${currentView}`] || [];
  const { isDoingRequest } = useSelector((state) => state.commonState);

  return (
    <Modal
      show={true}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size={size}
    >
      <Form onSubmit={handleSubmit(submits[`step_${currentView}`])}>
        <Modal.Header closeButton>
          <Wizard current={currentView} steps={steps} />
        </Modal.Header>
        <Modal.Body>{body[`step_${currentView}`]}</Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-wrap justify-content-end gap-2 px-2">
            <ButtonsList data={extraButtons} />
            <Button
              type="submit"
              text={submitBtnText[`step_${currentView}`]}
              title={submitBtnTitle[`step_${currentView}`]}
              disabled={submitBtnDisabled[`step_${currentView}`]}
              isDoingRequest={isDoingRequest}
            />
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default WizardModal;
