import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineInfoCircle
} from 'react-icons/ai';
import { BiBlock, BiLogOut, BiSolidEdit } from 'react-icons/bi';
import {
  BsFileEarmarkTextFill,
  BsFillCalendarWeekFill,
  BsFillFileEarmarkBarGraphFill,
  BsFillPeopleFill,
  BsJournalMedical,
  BsMegaphone,
  BsPerson,
  BsPersonFill,
  BsTable,
  BsTelephoneFill,
  BsTrashFill
} from 'react-icons/bs';
import {
  FaBars,
  FaCheckCircle,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaCircle,
  FaCommentDots,
  FaDiagnoses,
  FaFile,
  FaFileDownload,
  FaFileInvoiceDollar,
  FaFilter,
  FaHospital,
  FaRegClock,
  FaRegQuestionCircle,
  FaSave,
  FaSyringe,
  FaToggleOn,
  FaUser,
  FaUserAltSlash,
  FaVial,
  FaUserInjured
} from 'react-icons/fa';
import {
  FaBriefcaseMedical,
  FaFileArrowUp,
  FaFileCircleCheck,
  FaFileCircleExclamation,
  FaFileContract,
  FaMagnifyingGlass,
  FaPencil,
  FaPersonCircleCheck,
  FaPlus,
  FaQuestion,
  FaRegAddressCard,
  FaRegStar,
  FaSquare,
  FaSquareCheck,
  FaStar,
  FaTimeline,
  FaUserPlus
} from 'react-icons/fa6';

import { GiConfirmed, GiCycle } from 'react-icons/gi';
import { GoAlert, GoDotFill } from 'react-icons/go';
import { GrUndo, GrValidate } from 'react-icons/gr';
import { ImCancelCircle, ImCloudUpload } from 'react-icons/im';
import { IoMdSunny } from 'react-icons/io';
import { IoCheckmarkDoneOutline, IoReload } from 'react-icons/io5';
import {
  MdFindInPage,
  MdMeetingRoom,
  MdNewReleases,
  MdOutlineMedicalServices,
  MdOutlineRecordVoiceOver
} from 'react-icons/md';
import { PiArmchairFill, PiUploadSimple, PiFilesFill } from 'react-icons/pi';
import { RiFolderReceivedFill, RiSurveyFill } from 'react-icons/ri';
import { SiFormstack, SiPowerbi } from 'react-icons/si';
import {
  TbArmchair2Off,
  TbInputSearch,
  TbTriangleSquareCircleFilled
} from 'react-icons/tb';

export const ICONS = {
  alert: GoAlert,
  admission: TbInputSearch,
  bars: FaBars,
  ufcaForm: SiFormstack,
  dynamicSurveyPatient: FaDiagnoses,
  bills: BsFileEarmarkTextFill,
  block: BiBlock,
  cardId: FaRegAddressCard,
  calendar: BsFillCalendarWeekFill,
  cancel: ImCancelCircle,
  chair: PiArmchairFill,
  chairOccupied: TbArmchair2Off,
  check: FaCheckCircle,
  checkmark: IoCheckmarkDoneOutline,
  chevronUp: FaChevronUp,
  chevronLeft: FaChevronLeft,
  chevronRight: FaChevronRight,
  chevronDown: FaChevronDown,
  circle: FaCircle,
  cloudUpload: ImCloudUpload,
  clock: FaRegClock,
  comments: FaCommentDots,
  confirm: GiConfirmed,
  contract: FaFileContract,
  cycle: GiCycle,
  squareCheck: FaSquareCheck,
  dotFill: GoDotFill,
  edit: BiSolidEdit,
  eye: AiOutlineEye,
  eyeInvisible: AiOutlineEyeInvisible,
  file: FaFile,
  fileArrowUp: FaFileArrowUp,
  fileDownload: FaFileDownload,
  fileGraphFill: BsFillFileEarmarkBarGraphFill,
  filter: FaFilter,
  findPage: MdFindInPage,
  files: PiFilesFill,
  folderReceivedFill: RiFolderReceivedFill,
  hospital: FaHospital,
  inactivePatient: FaUserAltSlash,
  infoCircle: AiOutlineInfoCircle,
  invoicedPrinted: FaFileInvoiceDollar,
  journalMedical: BsJournalMedical,
  logout: BiLogOut,
  magnifyingGlass: FaMagnifyingGlass,
  medicalService: MdOutlineMedicalServices,
  megaphone: BsMegaphone,
  recordVoice: MdOutlineRecordVoiceOver,
  room: MdMeetingRoom,
  peopleFill: BsFillPeopleFill,
  pencil: FaPencil,
  person: BsPerson,
  personCircleCheck: FaPersonCircleCheck,
  personFill: BsPersonFill,
  plus: FaPlus,
  question: FaQuestion,
  questionCircle: FaRegQuestionCircle,
  releases: MdNewReleases,
  reload: IoReload,
  triangleSquareCircleFilled: TbTriangleSquareCircleFilled,
  save: FaSave,
  square: FaSquare,
  starFill: FaStar,
  starEmpty: FaRegStar,
  sun: IoMdSunny,
  supportEmpty: FaFileCircleExclamation,
  supportLoaded: FaFileCircleCheck,
  surveyFill: RiSurveyFill,
  syringe: FaSyringe,
  table: BsTable,
  telephoneFill: BsTelephoneFill,
  timeline: FaTimeline,
  trashFill: BsTrashFill,
  toggle: FaToggleOn,
  undo: GrUndo,
  user: FaUser,
  userPlus: FaUserPlus,
  validate: GrValidate,
  powerBi: SiPowerbi,
  preload: PiUploadSimple,
  specialty: FaBriefcaseMedical,
  userInjured: FaUserInjured,
  vial: FaVial
};

export const ICON_SIZES = {
  XS: 14,
  S: 16,
  MD: 24,
  LG: 32,
  XL: 50
};
