//* External
import axios from 'axios';
import { omit } from 'lodash';

//* App Custom
import { useLocalStorage } from '@hooksApp/index';
import {
  requestFinalized,
  saveServerError,
  saveUserError,
  store
} from '@reduxApp/index';
import { cleanObject } from './index';

const sendRequest = async ({
  url,
  method = 'get',
  data = null,
  params = null,
  isDatatable = false,
  thenFunction,
  catchFunction,
  finallyFunction,
  type = 'private',
  domainRequest = true
}) => {
  const { recaptcha, userAccess, userData } = useLocalStorage();

  const token = {
    private: `Token ${userAccess}`
  }[type];

  const mode = {
    development: import.meta.env.VITE_BACKEND_DEV_BASE_URL,
    test: import.meta.env.VITE_BACKEND_TEST_BASE_URL,
    production: import.meta.env.VITE_BACKEND_PROD_BASE_URL
  };
  const baseURL = mode[import.meta.env.MODE];

  const finalHttp =
    import.meta.env.MODE === 'development' ? 'http://' : 'https://';

  const finalPort = import.meta.env.MODE === 'development' ? ':8000' : '';

  let finalBaseURL = `${finalHttp}${baseURL}`;
  if (domainRequest) {
    finalBaseURL = `${finalHttp}${userData.domain_url}${finalPort}`;
  }

  let finalParams = params;
  if (isDatatable) {
    finalParams = omit(
      {
        format: 'datatables',
        length: params.length,
        start: params.length * params?.page || 0,
        ...params
      },
      ['page']
    );
  }

  let config = cleanObject({
    baseURL: finalBaseURL,
    url,
    method,
    data,
    params: finalParams,
    headers: cleanObject({
      Authorization: token,
      recaptcha: recaptcha
    })
  });

  await axios(config)
    .then((response) => thenFunction(response))
    .catch((error) => {
      // Error handling actions
      if (error?.response?.status >= 500) {
        store.dispatch(saveServerError());
      } else if (error?.response?.status >= 400) {
        store.dispatch(saveUserError(error.response?.data));
      }
      catchFunction && catchFunction(error);
      store.dispatch(requestFinalized());
    })
    .finally(() => {
      finallyFunction && finallyFunction();
    });
};

export default sendRequest;
