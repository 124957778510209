//* External
import { toast } from 'react-toastify';

//* App Custom
import { APIS, FAILED_SHOWDATA_REQUEST_STATE } from '@constantsApp/index';
import { tnl } from '@i18nApp/i18n';
import { doingRequest, requestFinalized, store } from '@reduxApp/index';
import { sendRequest, turnIntoFormData } from '@utilsApp/index';

export const getPatientList = ({ section, params, setPatients }) => {
  setPatients((prevState) => ({ ...prevState, isSearching: true }));

  const urlSelected = {
    all: APIS.PEOPLE.PATIENT,
    programming: APIS.PEOPLE.PATIENTS_WITH_NO_CYCLE,
    reprogramming: APIS.PEOPLE.REPROGRAMMING,
    authorization: APIS.PEOPLE.PATIENT_WITH_PENDING_AUTHORIZATION
  }[section];

  sendRequest({
    url: urlSelected,
    isDatatable: true,
    params,
    thenFunction: (res) => {
      if (res.status === 200) {
        setPatients({
          data: res.data.data,
          totalCount: res.data.recordsTotal,
          isSearching: false
        });
      } else setPatients(FAILED_SHOWDATA_REQUEST_STATE);
    },
    catchFunction: () => setPatients(FAILED_SHOWDATA_REQUEST_STATE)
  });
};

export const getPatientCycles = ({
  section,
  type = 'pathParam',
  patientPk,
  setPatientCycles
}) => {
  const urlSelected = {
    all: APIS.CHEMO.CYCLE,
    reprogramming: APIS.CHEMO.REPROGRAMMING_PATIENT,
    pending_authorization: APIS.CHEMO.CYCLE_PENDING_AUTHORIZATION
  }[section];
  setPatientCycles((prevState) => ({ ...prevState, isSearching: true }));
  const defaultParams = { format: 'datatables', length: 100 };

  sendRequest({
    url: type === 'pathParam' ? `${urlSelected}${patientPk}/` : urlSelected,
    params:
      type === 'pathParam'
        ? defaultParams
        : { ...defaultParams, patient_uuid: patientPk },
    thenFunction: (res) => {
      if (res.status === 200) {
        setPatientCycles({
          data: res.data.data,
          isSearching: false
        });
      } else setPatientCycles({ data: [], isSearching: false });
    },
    catchFunction: () => setPatientCycles({ data: [], isSearching: false })
  });
};

export const getInactivationsReason = ({ params, setInactivationReason }) => {
  sendRequest({
    url: APIS.PEOPLE.INACTIVATE_REASON,
    params,
    thenFunction: (res) => {
      if (res.status === 200) {
        setInactivationReason(res.data);
      } else setInactivationReason([]);
    },
    catchFunction: () => setInactivationReason([])
  });
};

export const getCancelCycleReasons = ({ setCancelCycleOptions }) => {
  sendRequest({
    url: APIS.CHEMO.CANCEL_CYCLE_REASONS,
    thenFunction: (res) => {
      if (res.status === 200) {
        const cancelOptions = res.data.map((reason) => ({
          label: reason.name,
          value: reason.id
        }));
        setCancelCycleOptions(cancelOptions);
      } else setCancelCycleOptions([]);
    },
    catchFunction: () => setCancelCycleOptions([])
  });
};

export const linkMedicalOrdersToPatient = async (patientPK, medicalOrders) => {
  let requestDone = false;
  const formData = turnIntoFormData(medicalOrders, 'medical_orders');
  formData.append('patient', patientPK);
  formData.append('length', medicalOrders.length);
  await sendRequest({
    url: APIS.CHEMO.MULTIPLE_MEDICAL_ORDERS,
    method: 'post',
    data: formData,
    thenFunction: (res) => {
      if (res.status === 201) {
        requestDone = true;
        toast.success(
          tnl('toasts.patient_medical_orders_linked', {
            files: medicalOrders.length
          })
        );
      }
    }
  });
  return requestDone;
};

export const inactivatePatient = ({ patientPk, data, onSuccess }) => {
  sendRequest({
    url: `${APIS.PEOPLE.PATIENT_INACTIVATE_REASON}${patientPk}`,
    method: 'patch',
    data,
    thenFunction: (res) => {
      if (res.status === 200) {
        toast.success(tnl('toasts.inactive_patient'));
        onSuccess();
      }
    }
  });
};

export const discardMedicalOrders = ({ data, onSuccess = () => {} }) => {
  store.dispatch(doingRequest());
  sendRequest({
    url: APIS.CHEMO.MULTIPLE_MEDICAL_ORDERS,
    method: 'patch',
    data,
    thenFunction: (res) => {
      if (res.status === 200) {
        toast.success(tnl('toasts.medical_orders_discarded'));
        store.dispatch(requestFinalized());
        onSuccess();
      }
    }
  });
};
