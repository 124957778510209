import { APIS } from '@constantsApp/index';
import { sendRequest } from '@utilsApp/index';

export const getCumsServices = ({ params = {}, setServices }) => {
  sendRequest({
    url: APIS.CHEMO.MEDICAL_ORDERS_CUM,
    params,
    thenFunction: (res) => {
      if (res.status === 200) {
        const servicesOptions = res.data.results.map((service) => ({
          label: `${service.code} - ${service.name}`,
          value: service.id
        }));
        setServices(servicesOptions);
      } else setServices([]);
    },
    catchFunction: () => setServices([])
  });
};

export const getCupsServices = ({ params = {}, setServices }) => {
  sendRequest({
    url: APIS.CHEMO.MEDICAL_ORDERS_CUP,
    params,
    thenFunction: (res) => {
      if (res.status === 200) {
        const servicesOptions = res.data.results.map((service) => ({
          label: `${service.code} - ${service.name}`,
          value: service.id
        }));
        setServices(servicesOptions);
      } else setServices([]);
    },
    catchFunction: () => setServices([])
  });
};
