//* External
import PropTypes from 'prop-types';
import { Form, Image } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//* App Custom
import AppLogo from '@assetsApp/app_logo_alt.svg';
import { Button, InputHandler } from '@componentsApp/common/index';
import { TicsocialWatermark } from '@componentsApp/custom/index';
import { ROUTES } from '@constantsApp/index';
import './PublicForm.scss';

const PublicForm = ({
  title,
  extraImg,
  firstElement = 'form',
  inputs = [],
  submit = {
    text: '',
    request: () => {}
  }
}) => {
  const navigate = useNavigate();
  const { request, text } = submit;
  const { control, handleSubmit, formState } = useForm({ mode: 'all' });
  const { isDoingRequest } = useSelector((state) => state.commonState);

  const direction = {
    form: 'publicForm_formFirst',
    extraImg: 'publicForm_extraImgFirst'
  }[firstElement];

  return (
    <div className={`publicForm ${direction}`}>
      <TicsocialWatermark />
      {extraImg && (
        <Image
          src={extraImg}
          className="publicForm_extraImg"
          alt="login_img"
          fluid
        />
      )}
      <div className="form_container">
        <Image
          src={AppLogo}
          alt="logo_app"
          className="publicForm_logoImg mb-3"
        />
        {title && <h2 className="fw-bold">{title}</h2>}
        <Form onSubmit={handleSubmit(request)} className="w-100">
          {inputs.map((input) => (
            <InputHandler key={input.name} {...input} control={control} />
          ))}
          <div className="d-flex flex-column align-items-center gap-3 mt-3">
            <Button
              type="submit"
              text={text}
              disabled={!formState.isValid || isDoingRequest}
              isDoingRequest={isDoingRequest}
            />
            <Button
              variant="link"
              text="buttons.return"
              onClick={() => navigate(ROUTES.ROOT)}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

PublicForm.propTypes = {
  title: PropTypes.string,
  extraImg: PropTypes.string,
  firstElement: PropTypes.oneOf(['form', 'extraImg']),
  inputs: PropTypes.arrayOf(PropTypes.object).isRequired,
  submit: PropTypes.exact({
    text: PropTypes.string,
    request: PropTypes.func
  }).isRequired
};

export default PublicForm;
