//* External
import AES from 'crypto-js/aes';
import UTF8 from 'crypto-js/enc-utf8';

//* App Custom
import { saveUserError, store } from '@reduxApp/index';

const useLocalStorage = () => {
  const app = 'khymei_app';

  const encryptUserData = (data) => {
    const dataEncrypted = AES.encrypt(
      JSON.stringify(data),
      import.meta.env.VITE_ENCRYPTION_KEY
    ).toString();
    return dataEncrypted;
  };

  const decryptUserData = (data) => {
    let userDataDecrypted = {};
    if (data !== null) {
      if (Array.from(data)[0] !== '{') {
        const bytes = AES.decrypt(data, import.meta.env.VITE_ENCRYPTION_KEY);
        userDataDecrypted = JSON.parse(bytes.toString(UTF8));
      } else {
        store.dispatch(
          saveUserError({ code: 'unknownError', detail: 'Invalid token.' })
        );
      }
    }
    return userDataDecrypted;
  };

  const userData = decryptUserData(localStorage.getItem(app));

  const userPermissions =
    decryptUserData(localStorage.getItem(app))?.permissions || [];

  const userAccess = decryptUserData(localStorage.getItem(app))?.token;

  const sidebarData =
    decryptUserData(localStorage.getItem(app))?.sidebarData || [];

  const recaptcha = JSON.parse(localStorage.getItem('recaptcha'));

  const setRecaptcha = (token) => {
    localStorage.setItem('recaptcha', JSON.stringify(token));
  };

  const removeRecaptcha = () => localStorage.removeItem('recaptcha');

  const updateUserData = (newUserData = {}) => {
    const prevUserData = decryptUserData(localStorage.getItem(app));
    const updatedUserData = { ...prevUserData, ...newUserData };
    localStorage.setItem(app, encryptUserData(updatedUserData));
  };

  return {
    userData,
    userAccess,
    userPermissions,
    sidebarData,
    recaptcha,
    setRecaptcha,
    removeRecaptcha,
    updateUserData
  };
};

export default useLocalStorage;
