import { omit } from 'lodash';
import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from '@constantsApp/index';
import { useLocalStorage } from '@hooksApp/index';
import { UserAuthenticated } from '@layoutsApp/index';

const PrivateRoute = () => {
  const { userData, recaptcha, removeRecaptcha } = useLocalStorage();

  useEffect(() => {
    if (recaptcha) removeRecaptcha();
  }, []);

  return (
    <>
      {Object.keys(omit(userData, ['domain'])).length > 0 ? (
        <UserAuthenticated>
          <Outlet />
        </UserAuthenticated>
      ) : (
        <Navigate to={ROUTES.ROOT} />
      )}
    </>
  );
};

export default PrivateRoute;
