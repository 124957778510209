import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import AppLogo from '@assetsApp/app_icon.svg';
import { CustomIcon, ErrorBoundaryFront } from '@componentsApp/common/index';
import { TicsocialWatermark } from '@componentsApp/custom/index';
import Sidebar from './Sidebar/Sidebar';
import './userAuthenticated.scss';

const UserAuthenticated = ({ children }) => {
  const location = useLocation();

  const windowWidth = window.innerWidth;
  const [collapse, setCollapse] = useState(windowWidth < 1024);

  useEffect(() => {
    if (windowWidth < 1024) setCollapse(true);
  }, [location.pathname]);

  return (
    <div className="userAuthenticated m-0 p-0">
      <TicsocialWatermark />
      <div
        className={`userAuthenticated_collapse_btn ${
          !collapse ? 'userAuthenticated_collapse_btn_sidebar_visible' : ''
        }`}
        onClick={() => setCollapse(!collapse)}
      >
        {!collapse ? (
          <CustomIcon name="chevronLeft" />
        ) : (
          <Image src={AppLogo} width="30px" />
        )}
      </div>
      <Sidebar collapse={collapse} />
      <ErrorBoundaryFront>
        <div className="userAuthenticated_bg">
          <div className="userAuthenticated_content">{children}</div>
        </div>
      </ErrorBoundaryFront>
    </div>
  );
};

export default UserAuthenticated;
