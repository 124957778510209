import { verifyData } from '@utilsApp/index';

export const surveyModulePreviewData = (module) => [
  {
    label: 'labels.survey_module_name',
    value: verifyData([module.name])
  },
  {
    label: 'labels.survey_module_number_of_questions',
    value: verifyData([module.question_count])
  }
];
