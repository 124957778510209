//* External
import { omit } from 'lodash';
import { toast } from 'react-toastify';

//* App Custom
import { APIS } from '@constantsApp/index';
import { tnl } from '@i18nApp/i18n';
import { requestFinalized } from '@reduxApp/index';
import { store } from '@reduxApp/store';
import sendRequest from '@utilsApp/sendRequest';

export const obtainRequiredInfo = async ({
  document_number,
  survey,
  setRequiredData
}) => {
  setRequiredData((prevState) => ({
    ...prevState,
    isDoingRequest: true
  }));

  //* Obtain patient demographics
  await sendRequest({
    url: APIS.PEOPLE.PATIENT,
    params: { document_number },
    thenFunction: async (patientRes) => {
      if (patientRes.status === 200) {
        const patientDemographics = patientRes.data.results?.[0] || {};
        if (Object.keys(patientDemographics).length > 0) {
          setRequiredData((prevState) => ({
            ...prevState,
            patientDemographics
          }));
          //* Obtain survey info
          await obtainAllSurveyInfo({
            survey,
            patientDemographics,
            setRequiredData
          });
        } else {
          setRequiredData((prevState) => ({
            ...prevState,
            patientDemographics,
            modules: []
          }));
          toast.error(tnl('toasts.patient_does_not_exists'));
        }
      }
    }
  });

  setRequiredData((prevState) => ({ ...prevState, isDoingRequest: false }));
};

export const obtainAllSurveyInfo = async ({
  survey,
  patientDemographics,
  setRequiredData,
  setModulesCount
}) => {
  setRequiredData((prev) => ({ ...prev, isDoingRequest: true }));

  await sendRequest({
    url: APIS.UFCA.SURVEY_MODULES,
    isDatatable: true,
    params: { survey, length: 100 },
    thenFunction: async (moduleRes) => {
      if (moduleRes.status === 200) {
        const modules = moduleRes.data.data;
        setRequiredData((prevState) => ({ ...prevState, modules }));

        //* Create/retrieve patient survey pk
        await sendRequest({
          url: APIS.UFCA.SURVEY_PATIENT,
          method: 'post',
          data: { patient: patientDemographics.pk, survey },
          thenFunction: async (surveyPatientRes) => {
            if (surveyPatientRes.status === 201) {
              const survey_patient = surveyPatientRes.data.pk;
              setRequiredData((prevState) => ({
                ...prevState,
                survey_patient
              }));

              //* Obtain previous answers from survey (if it was previously completed)
              await sendRequest({
                url: APIS.UFCA.ANSWERS,
                params: { survey_patient },
                thenFunction: (answerRes) => {
                  const modulesCounter = {};
                  Object.entries(
                    omit(answerRes.data, ['date_created', 'date_updated', 'id'])
                  ).map((answer) => {
                    const answerModule = Number(answer[1][2]);
                    const answerValue = answer[1][1];

                    if (setModulesCount) {
                      setModulesCount(() => {
                        if (answerValue) {
                          modulesCounter[answerModule] =
                            (modulesCounter[answerModule] || 0) + 1;
                        }
                        return modulesCounter;
                      });
                    }

                    return [
                      (answer[0] = answer[0].replace('question', 'q')),
                      (answer[1] = answer[1][1])
                    ];
                  });

                  setRequiredData((prevState) => ({
                    ...prevState,
                    isDoingRequest: false
                  }));
                }
              });
            }
          }
        });
      }
    }
  });
};

export const getSelectQuestionOptions = async (
  option_text,
  question,
  setOptionsInput
) => {
  await sendRequest({
    url: APIS.UFCA.OPTIONS,
    params: { question, option_text },
    thenFunction: (res) => {
      const options = res.data.map((option) => ({
        label: option.option_text,
        value: option.option_text
      }));
      setOptionsInput(options);
    }
  });
};

export const getModuleQuestions = ({ module, setModuleQuestions }) => {
  setModuleQuestions({ data: [], isSearching: true });

  sendRequest({
    url: APIS.UFCA.QUESTIONS,
    params: { module },
    thenFunction: async (res) => {
      setModuleQuestions({ data: res.data, isSearching: false });
    },
    catchFunction: () => {
      setModuleQuestions({ data: [], isSearching: false });
    }
  });
};

export const updateSurveyAnswers = ({ data, onSuccess = () => {} }) => {
  sendRequest({
    url: APIS.UFCA.ANSWER,
    method: 'patch',
    data,
    thenFunction: (res) => {
      if (res.status === 200) {
        store.dispatch(requestFinalized());
        toast.success(tnl('toasts.survey_registry_added'));
        onSuccess();
      }
    }
  });
};
