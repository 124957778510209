import { useState } from 'react';

const useWizard = () => {
  const [wizardLevel, setWizardLevel] = useState(0);
  const nextWizard = () => setWizardLevel(wizardLevel + 1);
  const previousWizard = () => setWizardLevel(wizardLevel - 1);

  return {
    wizardLevel,
    nextWizard,
    previousWizard
  };
};

export default useWizard;
