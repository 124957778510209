import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { InputHandler } from '@componentsApp/common';
import { INPUT_REQUIRED } from '@constantsApp/inputRules';
import { getSelectQuestionOptions } from '@pagesApp/Private/WorkUnit/Surveys/SurveysRequests';
import { EMPTY_SELECT, getFormattedDefaultValue } from '@utilsApp/index';
import { processFormula } from './SurveyQuestionsData';

const SurveyQuestions = ({
  questionsData = [],
  surveyForm,
  responseMapper = {},
  responseFormulaMapper = {},
  setResponseMapper = () => {},
  setResponseFormulaMapper = () => {},
  setFillingSurvey = () => {}
}) => {
  const [optionsInput, setOptionsInput] = useState([EMPTY_SELECT]);

  return (
    <div className="row">
      {questionsData.map((question) => {
        const inputName = `q_${question.pk}`;
        const inputHasFormula = question.formula.length > 0;
        const inputType = question.question_type.question_type.toLowerCase();
        const defaultValue = getFormattedDefaultValue(
          responseMapper[inputName] || question.default,
          inputType,
          question.multiple_choices
        );

        const isInputDisabled =
          inputHasFormula ||
          question.read_only ||
          (question.only_write_once && defaultValue ? true : false);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const inputFinalValue = useMemo(() => {
          if (inputHasFormula) {
            const newFinalValue = processFormula(
              question,
              defaultValue,
              { ...responseMapper, ...responseFormulaMapper },
              setResponseFormulaMapper
            );
            return `${newFinalValue}`;
          } else return defaultValue;
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [responseMapper]);

        let inputKey = `${inputName}`;
        if (inputHasFormula) {
          inputKey = `${inputName}_${inputFinalValue}`;
        }

        return (
          <InputHandler
            key={inputKey}
            type={inputType}
            control={surveyForm.control}
            name={inputName}
            label={question.question_text}
            labelHelper={question.question_description ?? undefined}
            placeholder={question.question_text}
            options={optionsInput}
            defaultValue={inputFinalValue}
            isMulti={question.multiple_choices}
            disabled={isInputDisabled}
            onInputChange={(search) => {
              const option_text = search ?? '';
              if (inputType === 'select' && question.search_option) {
                getSelectQuestionOptions(
                  option_text,
                  question.pk,
                  setOptionsInput
                );
              }
              if (inputType !== 'select') {
                setResponseMapper((prev) => ({ ...prev, [inputName]: search }));
              }
              setFillingSurvey && setFillingSurvey(true);
            }}
            onEventChange={(option) => {
              if (question.multiple_choices) {
                if (option.length > 0) {
                  setResponseMapper((prev) => {
                    const responses = option
                      .map((item) => item.value)
                      .join(',');
                    return { ...prev, [inputName]: responses };
                  });
                }
              } else {
                setResponseMapper((prev) => ({
                  ...prev,
                  [inputName]: option.value
                }));
              }
              setFillingSurvey && setFillingSurvey(true);
            }}
            onFocus={() => {
              if (inputType === 'select') {
                if (question.option_set.length > 0) {
                  const options = question.option_set.map((option) => ({
                    label: option.option_text,
                    value: option.option_text
                  }));
                  setOptionsInput([EMPTY_SELECT, ...options]);
                } else if (
                  question.option_set.length === 0 &&
                  optionsInput.length > 0
                ) {
                  setOptionsInput([EMPTY_SELECT]);
                }
              }
            }}
            onBlur={() => {
              if (optionsInput.length > 0) {
                setOptionsInput([EMPTY_SELECT]);
              }
            }}
            rules={question.required ? INPUT_REQUIRED : undefined}
            step={inputType === 'number' ? '0.01' : undefined}
            design="col-md-3"
          />
        );
      })}
    </div>
  );
};

SurveyQuestions.propTypes = {
  questionsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  surveyForm: PropTypes.object.isRequired,
  responseMapper: PropTypes.object.isRequired,
  responseFormulaMapper: PropTypes.object.isRequired,
  setResponseMapper: PropTypes.func.isRequired,
  setResponseFormulaMapper: PropTypes.func.isRequired,
  setFillingSurvey: PropTypes.func
};

export default SurveyQuestions;
