import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Accordion, DataMapper, LoaderWrapper } from '@componentsApp/common';
import { getModuleQuestions } from '@pagesApp/Private/WorkUnit/Surveys/SurveysRequests';
import { surveyModulePreviewData } from './SurveyModulesData';
import { getPreviousAnswers } from './SurveyModulesRequests';
import SurveyQuestions from './SurveyQuestions/SurveyQuestions';

const SurveyModules = ({
  data,
  surveyPk,
  surveyForm,
  responseMapper,
  responseFormulaMapper,
  setResponseMapper,
  setResponseFormulaMapper,
  setFillingSurvey
}) => {
  const [moduleQuestions, setModuleQuestions] = useState({
    isSearching: false,
    data: []
  });
  const [moduleSelected, setModuleSelected] = useState(null);

  useEffect(() => {
    if (surveyPk) {
      getPreviousAnswers({ surveyPk, setResponseMapper });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion
      data={data}
      header={(module) => (
        <div className="w-100">
          <DataMapper
            data={surveyModulePreviewData(module)}
            columnsDesign="col-12 col-md-6"
          />
        </div>
      )}
      onEnter={({ pk }) => {
        setModuleSelected(pk);
        getModuleQuestions({ module: pk, setModuleQuestions });
      }}
      body={({ pk }) => {
        return (
          <>
            {moduleSelected === pk && (
              <LoaderWrapper
                data={moduleQuestions.data}
                isSearching={moduleQuestions.isSearching}
              >
                <SurveyQuestions
                  questionsData={moduleQuestions.data}
                  surveyForm={surveyForm}
                  responseMapper={responseMapper}
                  responseFormulaMapper={responseFormulaMapper}
                  setResponseMapper={setResponseMapper}
                  setResponseFormulaMapper={setResponseFormulaMapper}
                  setFillingSurvey={setFillingSurvey}
                />
              </LoaderWrapper>
            )}
          </>
        );
      }}
    />
  );
};

SurveyModules.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  surveyPk: PropTypes.number,
  surveyForm: PropTypes.object.isRequired,
  responseMapper: PropTypes.object.isRequired,
  responseFormulaMapper: PropTypes.object.isRequired,
  setResponseMapper: PropTypes.func.isRequired,
  setResponseFormulaMapper: PropTypes.func.isRequired,
  setFillingSurvey: PropTypes.func
};

export default SurveyModules;
