//* External
import { useState } from 'react';

//* App Custom
import {
  Badge,
  Button,
  ButtonsList,
  CustomIcon,
  InputCheckbox,
  InputHandler,
  MoreInfoModal
} from '@componentsApp/common/index';
import { PERMISSIONS } from '@constantsApp/index';
import { INPUT_REQUIRED } from '@constantsApp/inputRules';
import { useLocalStorage } from '@hooksApp/index';
import { tnl } from '@i18nApp/i18n';
import {
  DATE_FORMATS,
  REGEX_OPTIONS,
  cleanArray,
  createDropdownOptions,
  formatDate,
  verifyData
} from '@utilsApp/index';
import { updateMedicalOrderAuthNumber } from './patientCyclesAccordionRequests';
const { userPermissions } = useLocalStorage();

export const commonCycleData = (cycle) => [
  {
    label: 'labels.application_number',
    value: verifyData([
      cycle?.application_set?.filter(
        (application) =>
          !['RESCHEDULED'].includes(application.application_status)
      )?.length
    ])
  },
  {
    label: 'labels.cycle_status',
    value: tnl(cycle?.status)
  }
];

export const applicationColumns = () => [
  {
    name: 'labels.chair',
    cell: ({ booking }) => verifyData([booking?.chair?.name])
  },
  {
    name: 'labels.application_day',
    cell: ({ name }) => verifyData([name])
  },
  {
    name: 'labels.protocol',
    cell: ({ protocol }) => verifyData([protocol?.name])
  },
  {
    name: 'labels.admission_number',
    cell: ({ cycle }) => verifyData([cycle?.number_admission])
  },
  {
    name: 'labels.start_date_time',
    cell: ({ booking }) =>
      verifyData([
        formatDate(booking?.start_date_time, DATE_FORMATS.FULL_DATE)
      ]),
    minWidth: '175px'
  },
  {
    name: 'labels.end_date_time',
    cell: ({ booking }) =>
      verifyData([formatDate(booking?.end_date_time, DATE_FORMATS.FULL_DATE)]),
    minWidth: '175px'
  },
  {
    name: 'labels.application_state',
    cell: ({ application_status }) => <Badge value={application_status} />
  },
  {
    name: 'labels.cancel_reason_and_observation',
    cell: ({
      cancel_observation,
      reason_cancel,
      room_manager_observation,
      user_cancel_cycle,
      validation_set
    }) => {
      const user_management =
        validation_set?.[0]?.user_management?.username ||
        user_cancel_cycle?.username;
      const cancel_comment =
        cancel_observation || room_manager_observation || reason_cancel?.name;

      return (
        <MoreInfoModal
          maxString={50}
          text={verifyData([cancel_comment, user_management], ' - ')}
        />
      );
    }
  }
];

export const cancelInputs = ({ cancelCycleOptions }) => [
  {
    type: 'select',
    name: 'reason_cancel_cycle_id',
    label: 'labels.close_cycle_reason',
    placeholder: 'placeholders.novelty',
    options: cancelCycleOptions,
    rules: INPUT_REQUIRED
  },
  {
    type: 'text-area',
    name: 'cancel_observation',
    label: 'labels.observations',
    placeholder: 'placeholders.observations',
    design: 'col-12'
  }
];

export const noveltyInputs = () => [
  {
    type: 'select',
    name: 'synchronized_status',
    label: 'labels.novelty',
    placeholder: 'placeholders.novelty',
    options: createDropdownOptions([
      'WITHOUT_MIPRES_AUTHORIZATION',
      'MEDICATION_OUT_OF_STOCK',
      'NOVELTY_WITH_AUTHORIZATION'
    ]),
    rules: INPUT_REQUIRED
  }
];

export const medicalOrderColumns = ({
  section,
  cycle,
  control,
  onlyRead = false,
  setShowModal = () => {},
  setMedicalOrders = () => {},
  setMedicalOrderPk = () => {},
  setIsInvoiceCheck = () => {}
}) => {
  const isCycleCanceled = ['CANCELED', 'CANCELED_OVERRIDE'].includes(
    cycle.status
  );

  const allMedicalOrdersHaveInvoicedPrinted = cycle.medicalorder_set?.every(
    (medical_order) => medical_order.state_bh === 'INVOICED_PRINTED'
  );

  const defaultColumns = [
    {
      name: 'labels.novelty',
      cell: ({ pk, synchronized_status }) => (
        <>
          {synchronized_status ? (
            <div
              className="cursor-pointer"
              onClick={() => {
                setMedicalOrderPk(pk);
                setShowModal('deleteNovelty');
              }}
            >
              <CustomIcon
                name="alert"
                color="var(--red-light)"
                size="30"
                title={tnl(synchronized_status)}
              />
            </div>
          ) : (
            <>
              {!onlyRead ? (
                <Button
                  variant="secondary"
                  iconName="alert"
                  border="none"
                  onClick={() => {
                    setMedicalOrderPk(pk);
                    setShowModal('addNovelty');
                  }}
                  disabled={isCycleCanceled}
                  title={
                    isCycleCanceled
                      ? 'labels.cycle_canceled'
                      : 'buttons.add_novelty'
                  }
                />
              ) : (
                ' - '
              )}
            </>
          )}
        </>
      )
    },
    {
      name: 'labels.medical_support',
      cell: ({ medical_support }) => (
        <Button
          variant="secondary"
          iconName="fileDownload"
          border="none"
          title="buttons.download_support"
          disabled={!medical_support}
          fileToDownload={medical_support}
        />
      )
    },
    {
      cell: ({ isInvoiceCheck }) => {
        return (
          <InputCheckbox
            name="isInvoiceCheck"
            disabled={!allMedicalOrdersHaveInvoicedPrinted}
            defaultValue={isInvoiceCheck}
            onInputChange={() => {
              setIsInvoiceCheck(!isInvoiceCheck);
            }}
          />
        );
      }
    },
    {
      name: 'labels.code',
      cell: ({ service }) => verifyData([service.code])
    },
    {
      name: 'labels.name',
      cell: ({ service }) => verifyData([service.name]),
      minWidth: '170px'
    },
    {
      name: 'labels.order_number',
      cell: ({ medical_order_number }) => verifyData([medical_order_number])
    },
    {
      name: 'labels.order_date',
      cell: ({ medical_order_date }) => verifyData([medical_order_date])
    },
    {
      name: 'labels.days_range',
      cell: ({ days_range }) => verifyData([days_range]),
      minWidth: '150px'
    },
    {
      name: 'labels.recommendations',
      cell: ({ recommendations, daily_frequency, medicalordermedicine }) => (
        <MoreInfoModal
          text={verifyData(
            cleanArray([
              recommendations || medicalordermedicine?.recommendations,
              daily_frequency || medicalordermedicine?.daily_frequency
            ]),
            ' - '
          )}
        />
      ),
      minWidth: '200px'
    },
    {
      name: 'labels.quantity_of_applications',
      cell: ({ quantity_of_applications }) =>
        verifyData([quantity_of_applications]),
      minWidth: '150px',
      isHidden: ![
        'bills_admissions',
        'bills_billing',
        'bills_pharmacy_regent',
        'bills_room_manager'
      ].includes(section)
    },
    {
      name: 'labels.authorization_numbers',
      cell: ({ pk, authorization }) => {
        const [isEditMode, setIsEditMode] = useState(false);
        const state_bh = authorization?.[0]?.authorization_status;
        const [orderNumber, setOrderNumber] = useState('');

        const isAuthorizationEnabled = [
          'IMPRESA APROBADA',
          'COBRADA',
          ' - '
        ].includes(verifyData([state_bh]));
        let showLabelAuthorization = true;
        if (
          [
            'patient_management_all',
            'patient_management_authorization',
            'bills_admissions'
          ].includes(section)
        ) {
          if (
            authorization.length == 0 ||
            (!isAuthorizationEnabled && section === 'bills_admissions')
          ) {
            showLabelAuthorization = false;
          }
        }

        const isAuthorNumberDisabled = !isEditMode || isCycleCanceled;

        return (
          <>
            <div className="d-flex gap-1" style={{ maxWidth: 200 }}>
              <InputHandler
                type="text"
                name="medical_order_number"
                iconName={
                  showLabelAuthorization && authorization.length > 0 && 'check'
                }
                iconColor={
                  showLabelAuthorization &&
                  authorization.length > 0 &&
                  'green-dark'
                }
                placeholder="placeholders.order_number"
                onInputChange={(value) => {
                  if (Number.isInteger(Number(value))) {
                    setOrderNumber(value);
                  } else {
                    setOrderNumber('');
                  }
                }}
                disabled={isAuthorNumberDisabled}
                defaultValue={
                  orderNumber ||
                  authorization.map((auth) => auth.number).join(', ')
                }
                rules={{
                  required: true,
                  pattern: {
                    value: REGEX_OPTIONS.number_valid,
                    message: 'form.error-number-pattern'
                  }
                }}
                control={control}
              />

              <ButtonsList
                data={[
                  {
                    variant: isEditMode ? 'primary' : 'secondary',
                    iconName: isEditMode ? 'save' : 'edit',
                    border: 'none',
                    isHidden: !userPermissions.includes(
                      PERMISSIONS.PATIENT_MANAGEMENT
                        .CAN_EDIT_AUTHORIZATION_NUMBER
                    ),
                    disabled: isCycleCanceled,
                    onClick: () => {
                      setIsEditMode(!isEditMode);
                      if (isEditMode) {
                        updateMedicalOrderAuthNumber({
                          cyclePk: cycle.pk,
                          data: {
                            medical_order_id: pk,
                            authorization_number: orderNumber
                          },
                          setMedicalOrders
                        });
                      }
                    }
                  }
                ]}
              />
            </div>
          </>
        );
      },
      minWidth: '300px'
    },
    {
      name: 'labels.state_bh',
      cell: ({ authorization }) => {
        const status = verifyData([authorization?.[0]?.authorization_status]);
        const number = verifyData([authorization?.[0]?.number]);
        const altText = {
          ANULADA: `${number} anulado en BH`,
          COBRADA: `${number} ya fue usado`,
          ' - ': ''
        }[status];

        return (
          <div className="d-flex flex-wrap">
            <p>
              <b>{status}</b>
            </p>
            <small>{altText}</small>
          </div>
        );
      },
      minWidth: '150px'
    }
  ];
  if (onlyRead)
    return [
      ...defaultColumns,
      {
        name: 'labels.last_sync_bh',
        cell: ({ authorization }) =>
          verifyData([
            formatDate(
              authorization?.[0]?.last_bh_update,
              DATE_FORMATS.FULL_DATE
            )
          ]),
        minWidth: '180px'
      },
      {
        name: 'labels.doctor_name',
        cell: ({ doctor }) => verifyData([doctor?.name]),
        minWidth: '250px'
      },
      {
        name: 'labels.medication_pos',
        cell: ({ medication_pos }) => verifyData([medication_pos]),
        minWidth: '130px'
      },
      {
        name: 'labels.number_mipres',
        cell: ({ number_mipres }) => verifyData([number_mipres])
      },
      {
        name: 'labels.dose',
        cell: ({ dose }) => verifyData([dose])
      },
      {
        name: 'labels.adjusted_dose_mq',
        cell: ({ adjusted_dose_mq }) => verifyData([adjusted_dose_mq])
      },
      {
        name: 'labels.theoretical_dose_mq',
        cell: ({ theoretical_dose_mq }) => verifyData([theoretical_dose_mq])
      }
    ];
  else return [...defaultColumns];
};

export const billingStatusTimeline = (selectedBilling) => [
  {
    label: 'labels.user',
    value: verifyData([selectedBilling?.user_discovery?.email])
  },
  {
    label: 'labels.observations',
    value: verifyData([selectedBilling?.manage_observation])
  }
];
