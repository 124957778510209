export default {
  NAV_BAR: {
    CAN_VIEW_DEPLOYMENT_NOTES: 'core.can_view_deployment_notes'
  },
  DASHBOARD: {
    dashboard_cuc: 'core.can_view_dashboard_cuc',
    dashboard_cc: 'core.can_view_dashboard_cc',
    dashboard_csb: 'core.can_view_dashboard_csb'
  },

  PATIENT_MANAGEMENT: {
    CAN_ADD_PATIENT: 'core.can_add_patient',
    CAN_ADD_CYCLE_TO_PATIENT: 'core.can_add_cycle_to_patient',
    CAN_CANCEL_CYCLE_TO_PATIENT: 'core.can_cancel_cycle_to_patient',
    CAN_INACTIVATE_PATIENT: 'core.can_inactivate_patient',
    CAN_EDIT_CYCLE_FROM_PATIENT: 'core.can_edit_cycle_from_patient',
    CAN_EDIT_AUTHORIZATION_NUMBER: 'core.can_edit_authorization_number',
    CAN_SYNC_MEDICAL_ORDERS: 'core.can_sync_medical_orders',
    CAN_UPDATE_MEDICAL_ORDERS: 'core.can_update_medical_orders',
    CAN_VIEW_PROGRAMMING_SECTION: 'core.can_view_programming_section',
    CAN_VIEW_REPROGRAMMING_SECTION: 'core.can_view_reprogramming_section',
    CAN_VIEW_NO_AUTH_OR_MEDICAL_ORDER_SECTION:
      'core.can_view_no_auth_or_medical_order_section',
    CAN_VIEW_MODULE_P_MANAGEMENT: 'core.can_view_module_p_management'
  },
  APPLICATIONS: {
    CAN_VALIDATE_APPLICATIONS_MODULE: 'core.can_validate_applications_module',
    CAN_CANCEL_APPLICATIONS: 'core.can_cancel_applications',
    CAN_CONFIRM_APPLICATIONS: 'core.can_confirm_applications',
    CAN_DELETE_MEDICAL_ORDERS_FROM_APPLICATION:
      'core.can_delete_medical_orders_from_application',
    CAN_EDIT_MEDICAL_ORDERS_FROM_APPLICATION:
      'core.can_edit_medical_orders_from_application',
    CAN_ADD_NEW_MEDICAL_ORDERS_TO_APPLICATION:
      'core.can_add_new_medical_orders_to_application',
    CAN_PRIORITIZE_APPLICATION: 'core.can_prioritize_application',
    CAN_DOWNLOAD_PATIENTS_REPORT_APPLICATIONS:
      'core.can_download_patients_report_applications',
    CAN_DOWNLOAD_MEDICINES_REPORT_APPLICATIONS:
      'core.can_download_medicines_report_applications',
    CAN_VIEW_HOSPITAL_DOCTOR_SECTION: 'core.can_view_hospital_doctor_section',
    CAN_VIEW_PROGRAMMING_CHIEF_SECTION:
      'core.can_view_programming_chief_section',
    CAN_VIEW_INDUCED_DEMAND_SECTION: 'core.can_view_induced_demand_section',
    CAN_VIEW_QF_SECTION_APPLICATIONS: 'core.can_view_qf_section_applications',
    CAN_VIEW_MODULE_APPLICATIONS: 'core.can_view_module_applications'
  },
  PATIENTS_CONFIRMED: {
    CAN_UPDATE_ADMISSION_NUMBER: 'core.can_update_admission_number',
    CAN_VIEW_MEDICAL_ORDERS: 'core.can_view_medical_orders',
    CAN_VIEW_SUPPLIES: 'core.can_view_supplies',
    CAN_DOWNLOAD_PATIENTS_REPORT_P_CONFIRMED:
      'core.can_download_patients_report_p_confirmed',
    CAN_DOWNLOAD_MEDICINES_REPORT_P_CONFIRMED:
      'core.can_download_medicines_report_p_confirmed',
    CAN_VIEW_ADMISSIONS_SECTION: 'core.can_view_admissions_section',
    CAN_VIEW_QF_SECTION_P_CONFIRMED: 'core.can_view_qf_section_p_confirmed',
    CAN_VIEW_FARMACY_REGENT_SECTION: 'core.can_view_farmacy_regent_section',
    CAN_VIEW_MODULE_P_CONFIRMED: 'core.can_view_module_p_confirmed'
  },
  ROOM_MANAGEMENT: {
    CAN_VIEW_ROOMS_PAGE: 'core.can_view_rooms_page',
    CAN_VIEW_ROOM_CHIEF_PAGE: 'core.can_view_room_chief_page'
  },
  BILLS: {
    CAN_VALIDATE_BILLING_APPLICATIONS: 'core.can_validate_billing_applications',
    CAN_UPDATE_BILLING_NUMBER: 'core.can_update_billing_number',
    CAN_VIEW_BILLING_ADMISSIONS_SECTION:
      'core.can_view_billing_admissions_section',
    CAN_VIEW_ROOM_CHIEF_SECTION: 'core.can_view_room_chief_section',
    CAN_VIEW_BILLING_FARMACY_REGENT_SECTION:
      'core.can_view_billing_farmacy_regent_section',
    CAN_VIEW_BILLING_SECTION: 'core.can_view_billing_section',
    CAN_VIEW_MIXTURES_TAB: 'core.can_view_mixtures_tab',
    CAN_VIEW_MODULE_BILLING: 'core.can_view_module_billing',
    CAN_SYNC_BILLING_BH_AUTHORIZATIONS:
      'core.can_sync_billing_bh_authorizations'
  },
  UFCA_FORM: {
    CAN_VIEW_MODULE_UFCA_FORMS: 'core.can_view_module_ufca_forms'
  },
  WORK_UNIT: {
    CAN_VIEW_INDICATORS_BTN_WORK_UNIT: 'core.can_view_indicators_btn_work_unit',
    CAN_VIEW_SURVEY_BTN_WORK_UNIT: 'core.can_view_survey_btn_work_unit',
    CAN_VIEW_PATIENT_BTN_WORK_UNIT: 'core.can_view_patient_btn_work_unit'
  },
  REPORTS: {
    CAN_VIEW_REPORTS_DATATABLE: 'core.can_view_reports_datatable',
    CAN_VIEW_REPORTS_BTN: 'core.can_view_reports_btn',
    CAN_VIEW_ALL_REPORTS: 'core.can_view_all_reports',
    CAN_VIEW_TRANSACTIONAL_REPORTS: 'core.can_view_transactional_reports',
    CAN_VIEW_POPULATION_REPORTS: 'core.can_view_population_reports'
  }
};
