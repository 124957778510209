import moment from 'moment-timezone';

import { cleanArray } from '@utilsApp/index';

const validateExpression = ({ expresionSplitted = [], responseMapper }) => {
  const finalExpression = expresionSplitted.map((value) => {
    if (value.includes('q_')) {
      let inputValue;
      //* if includes q then find q_ad that means question actual date
      if (value === 'q_ad') {
        inputValue = new Date().toJSON().slice(0, 10);
      } else inputValue = responseMapper[value];
      if (
        moment(inputValue, ['YYYY-MM-DD', 'YYYY-MM-DDTHH:mm'], true).isValid()
      ) {
        inputValue = `${new Date(inputValue).getTime() / 1000}`;
      } else if (moment(inputValue, 'HH:mm', true).isValid()) {
        inputValue = `${moment.duration(inputValue).asSeconds()}`;
      }
      return inputValue;
    } else return value;
  });
  return finalExpression;
};

const validateDate = ({ expresionSplitted = [], responseMapper }) => {
  const datesObtained = cleanArray(
    expresionSplitted[1].split(/,|\[|\]/).map((value) => {
      const inputValue = responseMapper[value];
      return inputValue;
    })
  );
  if (datesObtained.length > 0) {
    const finalDate = datesObtained.reduce((dateToSelect, actualDate) => {
      const dateSelected = new Date(dateToSelect);
      const dateReviewed = new Date(actualDate);
      if (expresionSplitted[0] === 'max') {
        return dateReviewed > dateSelected ? actualDate : dateToSelect;
      } else {
        return dateSelected < dateReviewed ? dateToSelect : actualDate;
      }
    });
    return finalDate;
  }
};

const tryEvalExpression = (expression, defaultValue) => {
  try {
    if (expression.length === cleanArray(expression).length) {
      const resultOfFormula = eval(expression.join(' '));
      return resultOfFormula;
    }
  } catch {
    return '';
  }
  return defaultValue;
};

export const processFormula = (
  question,
  defaultValue,
  responseMapper,
  setResponseFormulaMapper = () => {}
) => {
  let finalValue;
  const questionName = `q_${question.pk}`;
  const expresionSplitted = question.formula?.[0].expression.split(' ');

  if (['max', 'min'].includes(expresionSplitted[0])) {
    const finalDate = validateDate({
      expresionSplitted,
      responseMapper
    });
    setResponseFormulaMapper((prev) => ({
      ...prev,
      [questionName]: `${finalDate}`
    }));
    finalValue = finalDate;
  } else {
    const expression = validateExpression({
      expresionSplitted,
      responseMapper
    });
    finalValue = tryEvalExpression(expression, defaultValue);
    setResponseFormulaMapper((prev) => ({
      ...prev,
      [questionName]: finalValue
    }));
  }

  return finalValue;
};
