//* External
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

//* App Custom
import {
  DataMapper,
  DynamicFormSection,
  InputHandler,
  WizardModal,
  Wrapper
} from '@componentsApp/common/index';
import { useWizard } from '@hooksApp/index';
import { cleanObject } from '@utilsApp/index';
import './addPatientModal.scss';
import {
  patientInfoObtained,
  patientInputs,
  searchPatientInputs
} from './addPatientModalData';
import {
  createPatient,
  getCities,
  obtainDocumentTypes,
  obtainPatientData,
  patientExistsInPlatform
} from './addPatientModalRequests';

const AddPatientModal = ({ setShowModal, onSuccessRequest }) => {
  const { wizardLevel, nextWizard } = useWizard();
  const { handleSubmit, watch, control } = useForm({
    values: {
      phones: [{ number_type: '', number: '' }],
      medical_orders: []
    }
  });

  const [cities, setCities] = useState([]);
  const [patientData, setPatientData] = useState(null);
  const [documentTypes, setDocumentTypes] = useState([]);

  const originOptions = ['AVICENA', 'PARTICULAR'];
  const [patientOrigin, setPatientOrigin] = useState(originOptions[0]);

  useEffect(() => {
    obtainDocumentTypes(setDocumentTypes);
  }, []);

  const obtainCities = (params = {}) => {
    getCities({ params, setCities });
  };

  useEffect(() => {
    if (patientOrigin !== originOptions[0]) obtainCities();
  }, [patientOrigin]);

  const onSubmitSearchPatient = async (params) => {
    const patientExists = await patientExistsInPlatform(params);
    if (!patientExists) {
      setPatientOrigin(params.origin);
      if (params.origin === 'AVICENA') {
        obtainPatientData({ params, setPatientData, nextWizard });
      } else {
        setPatientData({
          documents: [
            {
              document_type: params.document_type.pk,
              number: params.document_number
            }
          ]
        });
        nextWizard();
      }
    }
  };

  const onSubmitCreatePatient = (data) => {
    let patient = {};
    if (patientOrigin !== originOptions[0]) {
      const [first_name, ...other_names] = data.names.split(' ');
      const [first_last_name, ...other_last_names] = data.last_names.split(' ');
      const second_name = other_names.join(' ');
      const second_last_name = other_last_names.join(' ');

      patient = cleanObject(
        omit(
          {
            ...data,
            first_name: first_name.toUpperCase(),
            second_name: second_name.toUpperCase(),
            first_last_name: first_last_name.toUpperCase(),
            second_last_name: second_last_name.toUpperCase(),
            documents: [
              {
                document_type_id: data.document_type.pk,
                number: data.document_number
              }
            ],
            creation_channel: 'PARTICULAR'
          },
          [
            'names',
            'last_names',
            'document_type',
            'document_number',
            'medical_orders'
          ]
        )
      );
    } else {
      const bhData = cleanObject(
        omit(patientData, ['document_type', 'city', 'state'])
      );
      const demographicData = omit(data, ['medical_orders']);
      patient = {
        ...bhData,
        ...demographicData,
        creation_channel: 'BEYOND_HEALTH'
      };
    }
    const medicalOrders = data.medical_orders;

    createPatient({
      patient,
      medicalOrders,
      onSuccessRequest
    });
  };

  return (
    <WizardModal
      currentView={wizardLevel}
      handleSubmit={handleSubmit}
      steps={['labels.patient_search', 'labels.add_procedure']}
      body={{
        step_0: (
          <div className="addPatientModal">
            <Row>
              {searchPatientInputs({
                originOptions,
                documentTypes
              }).map((input) => (
                <InputHandler {...input} key={input.name} control={control} />
              ))}
            </Row>
          </div>
        ),
        step_1: (
          <div className="addPatientModal">
            <Wrapper title="labels.general_data">
              {patientOrigin === originOptions[0] ? (
                <DataMapper data={patientInfoObtained(patientData)} />
              ) : (
                <Row>
                  {patientInputs({
                    patientData,
                    documentTypes,
                    cities,
                    obtainCities
                  }).map((input) => (
                    <InputHandler
                      key={input.name}
                      {...input}
                      control={control}
                    />
                  ))}
                </Row>
              )}
              <DynamicFormSection
                name="phones"
                icon="telephoneFill"
                control={control}
              />
            </Wrapper>
            <DynamicFormSection
              name="medical_orders"
              control={control}
              watch={watch}
            />
          </div>
        )
      }}
      submits={{
        step_0: onSubmitSearchPatient,
        step_1: onSubmitCreatePatient
      }}
      submitBtnText={{
        step_0: 'buttons.search',
        step_1: 'buttons.add_patient'
      }}
      onHide={() => setShowModal('none')}
      size="xl"
    />
  );
};

AddPatientModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  onSuccessRequest: PropTypes.func.isRequired
};

export default AddPatientModal;
