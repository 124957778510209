import { APIS } from '@constantsApp/index';
import { useLocalStorage } from '@hooksApp/index';
import { sendRequest, sidebarRequestInfoValidated } from '@utilsApp/index';

export const updateUserStorage = () => {
  const { updateUserData } = useLocalStorage();

  sendRequest({
    url: APIS.AUTH.USERS,
    thenFunction: async (res) => {
      if (res.status === 200) {
        updateUserData(res.data);
        await getSidebarData();
      }
    }
  });
};

export const getSidebarData = async () => {
  const { updateUserData, setNavigationErrors } = useLocalStorage();

  await sendRequest({
    url: APIS.CORE.NAV_BAR,
    thenFunction: (res) => {
      if (res.status === 200) {
        const sidebarData = sidebarRequestInfoValidated({
          data: res.data,
          setNavigationErrors
        });
        updateUserData({ sidebarData });
        window.location.reload();
      } else updateUserData({ sidebarData: [] });
    },
    catchFunction: () => updateUserData({ sidebarData: [] })
  });
};
