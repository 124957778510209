import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { CustomIcon } from '@componentsApp/common/index';

const SidebarItem = ({ data, location }) => {
  const style = useMemo(() => {
    if (data.power_bi) {
      return (
        `/platform/${data.functional_name}${location.search}` ===
        `${location.pathname}?${data.power_bi}`
      );
    } else return `/platform/${data.functional_name}` === location.pathname;
  }, [location]);

  return (
    <NavLink
      className={`sidebar_link ${style ? 'sidebar_link_active' : ''}`}
      to={
        !data.power_bi
          ? `/platform/${data.functional_name}`
          : `/platform/${data.functional_name}?${data.power_bi}`
      }
    >
      <CustomIcon name={data.icon} />
      <p className="text-break ms-2">{data.label}</p>
    </NavLink>
  );
};

export default SidebarItem;
