//* External
import { toast } from 'react-toastify';

//* App Custom
import { APIS } from '@constantsApp/index';
import { tnl } from '@i18nApp/i18n';
import { doingRequest, requestFinalized, store } from '@reduxApp/index';
import { sendRequest } from '@utilsApp/index';

export const obtainCycleMedicalOrders = (params, setMedicalOrders) => {
  sendRequest({
    url: APIS.CHEMO.MEDICAL_ORDER,
    params: { format: 'datatables', length: 100, ...params },
    thenFunction: (res) => {
      if (res.status === 200)
        setMedicalOrders({ data: res.data.data, isSearching: false });
    }
  });
};

export const syncMedicalOrders = ({ data, setMedicalOrders }) => {
  setMedicalOrders((prevState) => ({
    data: prevState.data,
    isSearching: true
  }));

  const prevState = () => {
    setMedicalOrders((prevState) => ({
      data: prevState.data,
      isSearching: false
    }));
  };

  sendRequest({
    url: APIS.CHEMO.SYNCHRONIZE_AUTHORIZATION,
    method: 'post',
    data,
    thenFunction: (res) => {
      if (res.status === 200) {
        const medicalOrdersUpdated = res.data.data.updated_records;
        if (medicalOrdersUpdated > 0) {
          obtainCycleMedicalOrders({ cycle_id: data.cycle }, setMedicalOrders);
          toast.success(
            tnl('toasts.medical_orders_updated', { medicalOrdersUpdated })
          );
        } else {
          toast.info(tnl('toasts.medical_orders_not_updated'));
          prevState();
        }
      } else prevState();
    },
    catchFunction: () => prevState()
  });
};

export const syncBHAuthorizations = ({ data, setMedicalOrders }) => {
  setMedicalOrders((prevState) => ({
    data: prevState.data,
    isSearching: true
  }));

  const prevState = () => {
    setMedicalOrders((prevState) => ({
      data: prevState.data,
      isSearching: false
    }));
  };

  sendRequest({
    url: APIS.BEYOND_HEALTH.SYNC_AUTHORIZATION,
    method: 'post',
    data,
    thenFunction: (res) => {
      if (res.status === 200) {
        const medicalOrdersUpdated = res.data?.[0]['Authorizations Updated'];
        toast.success(
          tnl('toasts.medical_orders_updated', { medicalOrdersUpdated })
        );
        obtainCycleMedicalOrders(
          { cycle_id: data.cycle_id, medical_order_status: 'ACTIVE' },
          setMedicalOrders
        );
      } else prevState();
    },
    catchFunction: () => prevState()
  });
};

export const updateMedicalOrderAuthNumber = ({
  cyclePk,
  data,
  setMedicalOrders
}) => {
  sendRequest({
    url: APIS.BEYOND_HEALTH.AUTHORIZATIONS,
    method: 'post',
    data,
    thenFunction: (res) => {
      if (res.status === 201) {
        toast.success(tnl('toasts.medical_order_updated'));
        obtainCycleMedicalOrders({ cycle_id: cyclePk }, setMedicalOrders);
      }
    }
  });
};

export const updateCycleMedicalOrders = ({
  pk,
  medical_order_ids,
  setMedicalOrders,
  setShowModal
}) => {
  store.dispatch(doingRequest());
  sendRequest({
    url: `${APIS.CHEMO.UPDATE_CYCLE_MEDICAL_ORDERS}${pk}/`,
    method: 'patch',
    data: { medical_order_ids },
    thenFunction: (res) => {
      if (res.status === 200) {
        store.dispatch(requestFinalized());
        toast.success(tnl('toasts.cycle_updated'));
        setMedicalOrders({
          data: res.data.medicalorder_set,
          isSearching: false
        });
        setShowModal('none');
      }
    }
  });
};

export const cancelCycle = ({ pk, data, onSuccess = () => {} }) => {
  store.dispatch(doingRequest());
  sendRequest({
    url: `${APIS.CHEMO.CYCLE_CANCELED}${pk}/`,
    method: 'patch',
    data,
    thenFunction: (res) => {
      if (res.status === 200) {
        store.dispatch(requestFinalized());
        toast.success(tnl('toasts.application_status_updated'));
        onSuccess();
      } else {
        toast.info(tnl('toast.application_status_not_updated'));
      }
    }
  });
};

export const addNoveltyToMedicalOrder = ({ pk, data, onSuccess }) => {
  sendRequest({
    url: `${APIS.CHEMO.MEDICAL_ORDER}${pk}/`,
    method: 'patch',
    data,
    thenFunction: (res) => {
      if (res.status === 200) {
        toast.success(tnl('toasts.novelty_added_to_medical_order'));
        onSuccess();
      }
    }
  });
};

export const deleteNoveltyToMedicalOrder = ({ pk, onSuccess }) => {
  store.dispatch(doingRequest());
  sendRequest({
    url: `${APIS.CHEMO.MEDICAL_ORDER}${pk}/`,
    method: 'patch',
    data: { synchronized_status: null },
    thenFunction: (res) => {
      if (res.status === 200) {
        toast.success(tnl('toasts.novelty_deleted_from_medical_order'));
        store.dispatch(requestFinalized());
        onSuccess();
      }
    }
  });
};

export const submitMedicalOrderToBilling = (data) => {
  sendRequest({
    //  url: `${APIS.CHEMO.}${pk}/` Pending to send API,
    method: 'post',
    data,
    thenFunction: (res) => {
      if (res.status === 201) {
        toast.success(tnl('toasts.medical_order_send_to_bills'));
        onSuccess();
      } else {
        toast.info(tnl('toasts.medical_order_not_send_to_bills'));
      }
    }
  });
};
