import { omit } from 'lodash';

import { APIS } from '@constantsApp/index';
import { cleanObject, sendRequest } from '@utilsApp/index';

export const getPreviousAnswers = ({ surveyPk, setResponseMapper }) => {
  sendRequest({
    url: APIS.UFCA.ANSWERS,
    params: { survey_patient: surveyPk },
    thenFunction: (answerRes) => {
      const dataFormatted = Object.entries(
        omit(answerRes.data, ['date_created', 'date_updated', 'id'])
      ).map((answer) => {
        return [
          (answer[0] = answer[0].replace('question', 'q')),
          (answer[1] = answer[1][1])
        ];
      });
      const answers = cleanObject(Object.fromEntries(dataFormatted));
      setResponseMapper(answers);
    }
  });
};
