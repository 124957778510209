//* External
import { toast } from 'react-toastify';

//* App Custom
import { APIS } from '@constantsApp/index';
import { tnl } from '@i18nApp/i18n';
import { linkMedicalOrdersToPatient } from '@pagesApp/Private/PatientManagement/patientManagementRequests';
import { doingRequest, requestFinalized, store } from '@reduxApp/index';
import { cleanArrayOfObjects, sendRequest } from '@utilsApp/index';

export const obtainDocumentTypes = (setDocumentTypes) => {
  sendRequest({
    url: APIS.PEOPLE.DOCUMENT_TYPE,
    params: { format: 'datatables', length: 100 },
    thenFunction: (res) => {
      if (res.status === 200) {
        setDocumentTypes(
          res.data.data.map((dt) => ({
            label: dt.name,
            value: { pk: dt.pk, code: dt.code }
          }))
        );
      }
    }
  });
};

export const getCities = ({ params, setCities = () => [] }) => {
  sendRequest({
    url: APIS.CORE.CITIES,
    params: { format: 'datatables', length: 100, ...params },
    thenFunction: (res) => {
      if (res.status === 200) {
        setCities(
          res.data.data.map((city) => ({
            label: city.name,
            value: city.pk
          }))
        );
      }
    }
  });
};

export const patientExistsInPlatform = async (params) => {
  store.dispatch(doingRequest());
  let exists = false;

  const khymeiParams = {
    document__document_type__code: params.document_type.code,
    document_number: params.document_number
  };

  await sendRequest({
    url: APIS.PEOPLE.PATIENT,
    params: khymeiParams,
    thenFunction: (res) => {
      store.dispatch(requestFinalized());
      if (res.status === 200 && res.data.count > 0) {
        exists = true;
        toast.error(tnl('toasts.patient_already_exists'));
      }
    }
  });
  return exists;
};

export const obtainPatientData = ({ params, setPatientData, nextWizard }) => {
  store.dispatch(doingRequest());

  const genderOptions = { M: 'MALE', F: 'FEMALE' };
  const bhParams = {
    document_type: params.document_type.code,
    document_number: params.document_number
  };

  let patient = {};
  let ipsData = {};
  let insurance_plans = [];
  sendRequest({
    url: APIS.BEYOND_HEALTH.PATIENTS,
    params: bhParams,
    thenFunction: async (res) => {
      if (res.data?.Error) {
        store.dispatch(requestFinalized());
        return toast.error(tnl(res.data.Error));
      } else if (res.status === 200) {
        patient = res.data;

        // Find insurance plans from patient in BH
        await sendRequest({
          url: APIS.BEYOND_HEALTH.COVERAGES,
          params: bhParams,
          thenFunction: (res) => {
            if (res.status === 200) {
              insurance_plans = res.data;
            }
          }
        });

        // Find service provider from patient in BH
        await sendRequest({
          url: APIS.BEYOND_HEALTH.PROVIDERS,
          params: bhParams,
          thenFunction: (res) => {
            if (res.status === 200) {
              ipsData = res.data[0];
            }
          }
        });

        const allPatientData = {
          first_name: patient.name.name.firstName,
          second_name: patient.name.name?.secondName,
          first_last_name: patient.name.name.firstFamily,
          second_last_name: patient.name.name?.secondFamily,
          birth_date: patient.birthDate,
          gender: genderOptions[patient.gender],
          documents: [
            {
              document_type_id: params.document_type.pk,
              number: params.document_number
            }
          ],
          emails: cleanArrayOfObjects([{ email: patient?.email }]),
          addresses: cleanArrayOfObjects([{ address: patient?.address }]),
          geo_city_id: patient.city_code,
          service_provider_name: ipsData.name,
          state: patient.state,
          city: patient.city,
          document_type: params.document_type.code,
          insurance_plans
        };

        setPatientData(allPatientData);
        nextWizard();
      }
      store.dispatch(requestFinalized());
    }
  });
};

export const createPatient = ({ patient, medicalOrders, onSuccessRequest }) => {
  store.dispatch(doingRequest());
  sendRequest({
    url: APIS.PEOPLE.PATIENT,
    method: 'post',
    data: patient,
    thenFunction: async (res) => {
      if (res.status === 201) {
        const patientPK = res.data.pk;
        toast.success(tnl('toasts.patient_created'));
        if (medicalOrders.length > 0) {
          await linkMedicalOrdersToPatient(patientPK, medicalOrders);
        }
        store.dispatch(requestFinalized());
        onSuccessRequest();
      }
    }
  });
};
