//* External
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';

//* App Custom
import {
  Button,
  CustomIcon,
  InputHandler,
  Wrapper
} from '@componentsApp/common/index';
import { tnl } from '@i18nApp/i18n';
import {
  cumInputs,
  medicalOrderInputs,
  medicalOrderTypes,
  phoneInputs
} from './DynamicFormSectionData';
import { getCumsServices, getCupsServices } from './DynamicFormSectionRequests';

const DynamicFormSection = ({ name = '', icon, control, watch = () => {} }) => {
  const { append, remove, fields: section } = useFieldArray({ control, name });

  const title = tnl(`labels.${name}`);
  const [services, setServices] = useState([]);
  const [medicalOrderTypeSelected, setMedicalOrderTypeSelected] =
    useState(null);

  const obtainServices = (type, params = {}) => {
    if (type.label === medicalOrderTypes[0].label)
      getCumsServices({ params, setServices });
    else if (type.label === medicalOrderTypes[1].label)
      getCupsServices({ params, setServices });
  };

  useEffect(() => {
    if (name === 'medicines') obtainServices(medicalOrderTypes[0]);
  }, []);

  return (
    <div>
      {icon ? (
        <div className="d-flex align-items-center gap-1 mb-1">
          <p className="fw-bold">{title}</p>
          <CustomIcon name={icon} />
        </div>
      ) : (
        <h4 className="fw-bold px-4 addPatientModal_subtitle">{title}</h4>
      )}
      {section.map((inputData, index) => {
        const inputsToDisplay = {
          medical_orders: medicalOrderInputs(inputData, index, {
            services,
            medicalOrderTypeSelected,
            obtainServices,
            setMedicalOrderTypeSelected,
            watch
          }),
          medicines: cumInputs(inputData, { services, obtainServices }),
          phones: phoneInputs(inputData)
        }[name];

        return (
          <div className="d-flex" key={`${name}[${index}]`}>
            <Col sm="10" md="11">
              <div className="d-flex flex-wrap">
                {inputsToDisplay.length <= 2 ? (
                  <>
                    {inputsToDisplay.map((input) => (
                      <InputHandler
                        {...input}
                        name={`${name}[${index}].${input.name}`}
                        control={control}
                      />
                    ))}
                  </>
                ) : (
                  <Wrapper className="mb-3" border="left">
                    <Row>
                      {inputsToDisplay.map((input) => (
                        <InputHandler
                          {...input}
                          name={`${name}[${index}].${input.name}`}
                          control={control}
                        />
                      ))}
                    </Row>
                  </Wrapper>
                )}
              </div>
            </Col>
            <Col sm="2" md="1" className={inputsToDisplay.length > 2 && 'mb-3'}>
              <Button
                iconName="trashFill"
                disabled={name === 'phones' && index === 0}
                className={`trash_button_${
                  inputsToDisplay.length <= 2 ? 'min' : 'fit'
                }`}
                border={inputsToDisplay.length <= 2 ? 'none' : 'right'}
                onClick={() => remove(index)}
              />
            </Col>
          </div>
        );
      })}
      <div className="mt-3">
        <Button
          variant="secondary"
          iconName="plus"
          text={`buttons.add_${name}`}
          onClick={() => append({})}
        />
      </div>
    </div>
  );
};

export default DynamicFormSection;
